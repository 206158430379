.editPolicyForm {
  .button-container {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .label-text {
    font-weight: bold;
    
    &.editable {
      color: #344767;
    }
    
    .required-field {
      color: red;
      margin-left: 2px;
    }
  }

  .text-value {
    margin-left: 8px;
  }

  .MuiGrid-item.field-container {
    display: flex;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    border: 1px;
    font-size: 1rem;
  }
  
  .field-container {
    display: flex;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 1rem;
  }
  
  .traveler-card {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    
    .traveler-header {
      margin-bottom: 12px;
      font-size: 1.1rem;
    }
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    
    .button {
      margin-left: 1rem;
    }
  }
  
  .error-message {
    color: red;
    font-size: 0.75rem;
    margin-top: 0.25rem;
  }
  
  .section-divider {
    margin: 2rem 0;
  }
} 